<!--------------------------------------------------
  システム名：弁当注文ビフォサック（タブレット版）
  プログラム名：App.vue
  モジュール名：ルートコンポーネント
  備考：
-------------------------------------------------->
<template>
  <v-app>
    <!-- システムバー -->
    <SystemBar />
    <!-- 確認ダイアログ -->
    <ConfirmDialog ref="confirm" />
    <!-- スナックバー -->
    <SnackBar ref="snackbar" />
    <!-- 画面コンポーネント -->
    <router-view />
    <!-- デバッグパネル -->
    <DebugPanel v-if="debugMode" />
  </v-app>
</template>

<script>
import SystemBar from "@/components/SystemBar";
import ConfirmDialog from "@/components/ConfirmDialog";
import SnackBar from "@/components/SnackBar";
import DebugPanel from "@/components/DebugPanel";

export default {
  data() {
    return {
      idleTimer: null, // ログアウトまでのタイマー
      warningTimer: null, // 警告メッセージ表示用タイマー
      idleTime: 30000, // 30秒（30,000ms）
      warningTime: 20000, // 20秒（20,000ms）で警告表示
      autoLogout: true,
      debugMode: process.env.VUE_APP_DEBUG_MODE == "1",
    };
  },
  components: {
    SystemBar,
    SnackBar,
    ConfirmDialog,
    DebugPanel,
  },
  methods: {
    // **************************************************
    // メソッド
    // **************************************************
    // --------------------------------------------------
    // タイマーリセット
    // --------------------------------------------------
    resetTimer() {
      if (!this.autoLogout) return;

      // ログインしていない場合は何もしない
      if (!this.isLogin) return;

      // 既存のタイマーをクリア
      if (this.idleTimer) clearTimeout(this.idleTimer);
      if (this.warningTimer) clearTimeout(this.warningTimer);

      // 警告メッセージをリセット
      this.showWarning = false;

      // 20秒後に警告を表示
      this.warningTimer = setTimeout(() => {
        if (!this.isLogin) return; // ログアウト済みなら処理しない
        this.showWarning = true;
        this.$refs.snackbar.showMessage("あと10秒で自動的にログアウトされます。\n操作を続ける場合は画面を動かしてください。", "info");
      }, this.warningTime);

      // 30秒後にログアウト実行
      this.idleTimer = setTimeout(() => {
        this.logout("しばらく操作がなかったため、自動的にログアウトしました。\n続けるには再度ログインしてください。");
      }, this.idleTime);
    },

    // --------------------------------------------------
    // ログアウト
    // --------------------------------------------------
    logout(message = "ログアウトしました。") {
      if (this.$route.path != "/login") {
        this.$store.commit("unsetLogin");
        this.$router.push("/login");
        this.$refs.snackbar.showMessage(message, "info");
      }
    },
  },
  watch: {
    // isLoginの変更を監視し、ログインしたらタイマーを開始
    isLogin(newValue) {
      if (newValue) {
        this.resetTimer();
      } else {
        // ログアウト時はタイマーを停止
        if (this.idleTimer) clearTimeout(this.idleTimer);
        if (this.warningTimer) clearTimeout(this.warningTimer);
      }
    },
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    }, // Vuexのログイン状態を取得
  },
  mounted() {
    console.log(this.debugMode);
    this.$root.logout = this.logout;
    this.$root.confirm = this.$refs.confirm;
    this.$root.snackbar = this.$refs.snackbar;
    // イベントリスナーを設定
    window.addEventListener("mousemove", this.resetTimer);
    window.addEventListener("keydown", this.resetTimer);
    window.addEventListener("touchstart", this.resetTimer);
    window.addEventListener("touchmove", this.resetTimer);
    window.addEventListener("touchend", this.resetTimer);
    // 初回チェック（ログイン中ならタイマー開始）
    if (this.isLogin) {
      this.resetTimer();
    }
  },
  beforeDestroy() {
    // イベントリスナーを削除
    window.removeEventListener("mousemove", this.resetTimer);
    window.removeEventListener("keydown", this.resetTimer);
    window.removeEventListener("touchstart", this.resetTimer);
    window.removeEventListener("touchmove", this.resetTimer);
    window.removeEventListener("touchend", this.resetTimer);
    if (this.idleTimer) clearTimeout(this.idleTimer);
    if (this.warningTimer) clearTimeout(this.warningTimer);
  },
};
</script>
