import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    // システム情報
    nowDate: "", // 現在日時
    statAPI: false, // API接続状況
    statDB: false, // DB接続状況
    statWebSocket: false, // WebSocket接続状況
    statCardReader: false, // カードリーダ接続状況
    // ログイン状態
    isLogin: false,
    // 企業情報
    kigyoCode: "", // 企業コード
    kigyoName: "", // 企業名
    kigyoPlan: "0", // プラン
    kigyoSimebi: "", // 締め日
    kigyoIcOption: "", // ICカードオプション
    // 事業所情報
    jigyoNo: "0", // 事業所No
    jigyoName: "", // 事業所名
    // 部署情報
    bushoNo: "0", // 所属部署コード
    bushoName: "", // 所属部署名
    // 利用者情報
    userCode: "", // 利用者コード
    userName: "", // 氏名
    adminAuth: "0", // 管理者権限
    jigyoAuth: "0", // 事業所管理者権限
  };
};

const store = new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    setDate(state, date) {
      state.nowDate = date;
    },
    setAPI(state, api) {
      state.statAPI = api;
    },
    setDB(state, db) {
      state.statDB = db;
    },
    setWebSocket(state, websocket) {
      state.statWebSocket = websocket;
    },
    setCardReader(state, cardreader) {
      state.statCardReader = cardreader;
    },
    setAuth(state, params) {
      state.kigyoCode = params.str_kigyo_cd;
      state.kigyoName = params.str_kigyo_name;
      state.kigyoPlan = params.num_plan;
      state.kigyoSimebi = params.num_simebi;
      state.kigyoIcOption = params.num_ic_option;
    },
    setLogin(state, params) {
      state.isLogin = true;
      state.jigyoNo = params.num_jigyo_no;
      state.jigyoName = params.str_jigyo_name;
      state.bushoNo = params.num_busho_no;
      state.bushoName = params.str_busho_name;
      state.userCode = params.str_user_cd;
      state.userName = params.str_user_name;
      state.adminAuth = params.num_kanri_flg;
      state.jigyoAuth = params.num_jigyo_kanri;
    },
    unsetLogin(state) {
      state.isLogin = false;
      state.jigyoNo = "0";
      state.jigyoName = "";
      state.bushoNo = "0";
      state.bushoName = "";
      state.userCode = "";
      state.userName = "";
      state.adminAuth = "0";
      state.jigyoAuth = "0";
    },
    unsetAuth(state) {
      state.kigyoCode = "";
      state.kigyoName = "";
      state.kigyoPlan = "0";
      state.kigyoSimebi = "";
      state.kigyoIcOption = "";
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    getLogin: (state) => {
      return state.isLogin;
    },
  },
  actions: {
    setDate(context, date) {
      context.commit("setDate", date);
    },
    setAPI(context, api) {
      context.commit("setAPI", api);
    },
    setDB(context, db) {
      context.commit("setDB", db);
    },
    setWebSocket(context, websocket) {
      context.commit("setWebSocket", websocket);
    },
    setCardReader(context, cardreader) {
      context.commit("setCardReader", cardreader);
    },
    setAuth(context, params) {
      context.commit("setAuth", params);
    },
    setLogin(context, params) {
      context.commit("setLogin", params);
    },
  },
  plugins: [
    createPersistedState({
      // ストレージのキーを指定。デフォルトではvuex
      key: "befosc-terminal",
      // 管理対象のステートを指定
      // paths: [],
      // ストレージの種類を指定。デフォルトではローカルストレージ
      // storage: window.sessionStorage,
      storage: window.localStorage,
    }),
  ],
});

export default store;
