import { getRequest, postRequest } from "./endpoint";

// アクセス元識別文字列
const sAccess = "TAB";

// ****************************************
// 接続チェック
// ****************************************
// ----------------------------------------
// APIサーバー
// ----------------------------------------
export async function getCheckServer() {
  return await getRequest("/auth/server");
}
// ----------------------------------------
// DB
// ----------------------------------------
export async function getCheckDB() {
  return await getRequest("/auth/db");
}

// ****************************************
// ログイン
// ****************************************
// ----------------------------------------
// ID・パスワード認証
// ----------------------------------------
// export async function postAuthLogin(KigyoCd, Login, Password) {
//   const params = new URLSearchParams();
//   params.append("KigyoCd", KigyoCd);
//   params.append("Login", Login);
//   params.append("Password", Password);
//   return await postRequest("/auth/login", params);
// }
// ----------------------------------------
// ICカード認証
// ----------------------------------------
export async function postAuthCard(KigyoCd, IcIdm) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("IcIdm", IcIdm);
  return await postRequest("/auth/card", params);
}
// ----------------------------------------
// QRコード認証
// ----------------------------------------
// export async function postAuthQr(KigyoCd, UserCd) {
//   const params = new URLSearchParams();
//   params.append("KigyoCd", KigyoCd);
//   params.append("UserCd", UserCd);
//   return await postRequest("/auth/qr", params);
// }
// ----------------------------------------
// 社員コード認証
// ----------------------------------------
export async function postAuthEmployee(KigyoCd, EmployeeID) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("EmployeeID", EmployeeID);
  return await postRequest("/auth/employee", params);
}

// ****************************************
// 弁当マスタ
// ****************************************
// ----------------------------------------
// メニュー一覧取得（業者毎）
// ----------------------------------------
export async function getBentoList(KigyoCd, JigyoNo, GyoshaNo, Chumonbi) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
    GyoshaNo: GyoshaNo,
    Chumonbi: Chumonbi,
  };
  return await getRequest("/m_bento_get/bento_list", params);
}
// ----------------------------------------
// メニュー一覧取得（事業所毎）
// ----------------------------------------
export async function getBentoListJigyo(KigyoCd, JigyoNo, Chumonbi) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
    Chumonbi: Chumonbi,
  };
  return await getRequest("/m_bento_get/bento_list_jigyo", params);
}

// ****************************************
// 弁当業者マスタ
// ****************************************
// ----------------------------------------
// 業者一覧取得
// ----------------------------------------
export async function getGyoshaList(KigyoCd, JigyoNo) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
  };
  return await getRequest("/m_gyosha_get/gyosha_list", params);
}

// ****************************************
// 事業所マスタ
// ****************************************
// ----------------------------------------
// 事業所一覧取得
// ----------------------------------------
export async function getJigyoList(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_jigyo_get/jigyosyo_list", params);
}

// ****************************************
// 企業マスタ
// ****************************************
// ----------------------------------------
// 企業情報取得
// ----------------------------------------
export async function getKigyoInfo(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_kigyo_get/info", params);
}

// ****************************************
// 利用者マスタ
// ****************************************
// ----------------------------------------
//
// ----------------------------------------
export async function getUserRiyoList(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_user_get/riyo_list", params);
}
// ----------------------------------------
//
// ----------------------------------------
export async function postUserIcUpdate(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("", params);
}

// ****************************************
// 年間予定テーブル
// ****************************************
// ----------------------------------------
// 予定一覧取得
// ----------------------------------------
export async function getCalendarList(KigyoCd, Year) {
  const params = {
    KigyoCd: KigyoCd,
    Year: Year,
  };
  return await getRequest("/t_calendar_get/list", params);
}

// ****************************************
// 注文予約テーブル
// ****************************************
// ----------------------------------------
// 予約一覧取得（月毎：3カ月）
// ----------------------------------------
export async function getChuYoyakuListMonthly(KigyoCd, UserCd, Chumonbi) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
    Chumonbi: Chumonbi,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_list_monthly", params);
}
// ----------------------------------------
// 予約一覧取得（日毎）
// ----------------------------------------
export async function getChuYoyakuListDaily(KigyoCd, UserCd, Chumonbi) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
    Chumonbi: Chumonbi,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_list_daily", params);
}
// ----------------------------------------
// 請求金額取得（月毎）
// ----------------------------------------
export async function getChuYoyakuMonthlyPrice(KigyoCd, UserCd, Simebi) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
    Simebi: Simebi,
  };
  return await getRequest("/t_chu_yoyaku_get/monthly_price", params);
}
// ----------------------------------------
// 注文予約登録
// ----------------------------------------
export async function postChuYoyakuInsert(KigyoCd, JigyoNo, UserCd, Chu_st, Chu_ed, Chu_week, GyoshaNo, BentoNo, Kingaku, Kosuu, Goukei, sUser) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("JigyoNo", JigyoNo);
  params.append("UserCd", UserCd);
  params.append("Chu_st", Chu_st);
  params.append("Chu_ed", Chu_ed);
  params.append("Chu_week", Chu_week);
  params.append("GyoshaNo", GyoshaNo);
  params.append("BentoNo", BentoNo);
  params.append("Kingaku", Kingaku);
  params.append("Kosuu", Kosuu);
  params.append("Goukei", Goukei);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/t_chu_yoyaku_post/insert", params);
}
// ----------------------------------------
// 注文予約削除
// ----------------------------------------
export async function postChuYoyakuDelete(sUser, tKigyoCd, tJigyoNo, tUserCd, tChumonbi, tSeqNo) {
  const params = new URLSearchParams();
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  params.append("tKigyoCd", tKigyoCd);
  params.append("tJigyoNo", tJigyoNo);
  params.append("tUserCd", tUserCd);
  params.append("tChumonbi", tChumonbi);
  params.append("tSeqNo", tSeqNo);
  return await postRequest("/t_chu_yoyaku_post/delete", params);
}
// ----------------------------------------
// 注文受取登録
// ----------------------------------------
export async function postChuYoyakuReceipt(sUser, tKigyoCd, tJigyoNo, tUserCd, tChumonbi) {
  const params = new URLSearchParams();
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  params.append("tKigyoCd", tKigyoCd);
  params.append("tJigyoNo", tJigyoNo);
  params.append("tUserCd", tUserCd);
  params.append("tChumonbi", tChumonbi);
  return await postRequest("/t_chu_yoyaku_post/receipt", params);
}

// ****************************************
// ログイン履歴テーブル
// ****************************************
// ----------------------------------------
// ログイン履歴登録
// ----------------------------------------
export async function postLoginInsert(KigyoCd, UserCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("UserCd", UserCd);
  params.append("sAccess", sAccess);
  return await postRequest("/t_login_post/insert", params);
}

// ****************************************
// お知らせテーブル
// ****************************************
// ----------------------------------------
// お知らせ一覧取得
// ----------------------------------------
export async function getNoticeList(KigyoCd, UserCd) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
  };
  return await getRequest("/t_notice_get/list", params);
}
// ----------------------------------------
// お知らせ既読登録
// ----------------------------------------
export async function postNoticeRead(NoticeKigyoCd, NoticeNo, KigyoCd, UserCd, sUser) {
  const params = new URLSearchParams();
  params.append("NoticeKigyoCd", NoticeKigyoCd);
  params.append("NoticeNo", NoticeNo);
  params.append("KigyoCd", KigyoCd);
  params.append("UserCd", UserCd);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/t_notice_post/read", params);
}

// ****************************************
// 休日テーブル
// ****************************************
// ----------------------------------------
// 休日情報取得
// ----------------------------------------
export async function getHoliday(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_holiday_get/holiday", params);
}
// ----------------------------------------
// 休日情報取得（３か月）
// ----------------------------------------
export async function getHolidayMonthly(KigyoCd, SelYMD) {
  const params = {
    KigyoCd: KigyoCd,
    SelYMD: SelYMD,
  };
  return await getRequest("/m_holiday_get/holiday_monthly", params);
}

// ****************************************
// 祝日テーブル
// ****************************************
// ----------------------------------------
// 祝日情報取得（3か月）
// ----------------------------------------
export async function getJapanHolidayMonthly(YearMonth) {
  const params = {
    YearMonth: YearMonth,
  };
  return await getRequest("/m_japan_holiday_get/list_monthly", params);
}
