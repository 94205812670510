import store from "@/store";
import * as api from "@/api/client";
import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

dayjs.locale(ja);

// リトライ回数
// let retryCnt = 0;

// **************************************************
// 初期処理
// **************************************************
async function initialize() {
  // API接続チェック
  const apiStatus = await checkApiServer();
  store.dispatch("setAPI", apiStatus);
  // DB接続チェック
  const dbStatus = await checkDatabase();
  store.dispatch("setDB", dbStatus);
  // NFC接続チェック
  // const nfcStatus = await checkNfc();
  // store.dispatch("setCardReader", nfcStatus);
}

// **************************************************
// サーバー接続チェック
// **************************************************
async function checkApiServer() {
  try {
    const responce = await api.getCheckServer();
    return responce.data["result"];
  } catch {
    return false;
  }
}

// **************************************************
// データベース接続チェック
// **************************************************
async function checkDatabase() {
  try {
    const responce = await api.getCheckDB();
    return responce.data["result"];
  } catch {
    return false;
  }
}

// **************************************************
// NFC接続チェック
// **************************************************
// async function checkNfc() {
//   if ("NDEFReader" in window) {
//     return false;
//   } else {
//     return false;
//   }
// }

// **************************************************
// カードリーダー接続チェック
// **************************************************
// async function connectWebSocket() {
//   const ws = new WebSocket(`ws://localhost:${process.env.VUE_APP_WEBSOCKET_PORT}`);

//   ws.onmessage = (event) => {
//     const data = JSON.parse(event.data);

//     // Pasoriの接続状態を更新
//     if (data.message && data.connected != undefined) {
//       store.dispatch("setCardReader", data.connected);
//     }
//   };

//   ws.onopen = () => {
//     store.dispatch("setWebSocket", true);
//     console.log("WebSocket接続成功");
//   };
//   ws.onerror = (err) => console.error("WebSocketエラー", err);
//   ws.onclose = async () => {
//     store.dispatch("setWebSocket", false);
//     store.dispatch("setCardReader", false);
//     console.log("WebSocket切断");
//     if (retryCnt <= 3) {
//       retryCnt += 1;
//       window.setTimeout(await connectWebSocket(), 10000);
//     }
//   };
// }
// connectWebSocket();

// **************************************************
// 日時セット
// **************************************************
function timeFormate(timeStamp) {
  let nowDate = dayjs(timeStamp).format("YYYY/MM/DD HH:mm:ss");
  return nowDate;
}

// 起動時チェック
initialize().catch(console.error);
// 10分置きに接続状況をチェック
setInterval(async () => {
  initialize().catch(console.error);
}, 60000);
// 1秒おきに時刻を更新
setInterval(() => {
  store.dispatch("setDate", timeFormate(new Date()));
}, 1000);
