import axios from "axios";
import axiosRetry from "axios-retry";

// API接続情報
const apiURL = process.env.VUE_APP_API_ORIGIN;
const apiUsername = process.env.VUE_APP_API_USER;
const apiPassword = process.env.VUE_APP_API_PASS;
const apiTimeout = 10000;

// axiosインスタンス生成
const instance = axios.create({
  baseURL: apiURL,
  auth: { username: apiUsername, password: apiPassword },
  timeout: apiTimeout,
});

// エラーハンドリング
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // let errorCode = "詳細：";
    // const isTimeout = error.code === "ECONNABORTED";
    // if (isTimeout) {
    //   errorCode += "Timeout (" + apiTimeout + "ms)";
    // } else if (!error.response) {
    //   switch (error.response?.status) {
    //     case 400:
    //       errorCode += "400 Bad Request";
    //       break;
    //     case 401:
    //       errorCode += "401 Unauthorized";
    //       break;
    //     case 403:
    //       errorCode += "403 Forbidden";
    //       break;
    //     case 404:
    //       errorCode += "404 Not Found";
    //       break;
    //     case 405:
    //       errorCode += "405 Method Not Allowed";
    //       break;
    //     case 500:
    //       errorCode += "500 Internal Server Error";
    //       break;
    //     case 501:
    //       errorCode += "501 Not Implemented";
    //       break;
    //     case 502:
    //       errorCode += "502 Bad Gateway";
    //       break;
    //     case 503:
    //       errorCode += "503 Service Unavailable";
    //       break;
    //     case 504:
    //       errorCode += "504 Gateway Timeout";
    //       break;
    //     default:
    //       errorCode = error;
    //       break;
    //   }
    // }
    // return Promise.reject(errorCode);
    return Promise.reject(error);
  }
);

// リトライ
axiosRetry(instance, {
  retries: 2, // 回数
  retryCondition: () => true, // 条件（true:4XX/5XXエラー発生時, false:5XXエラー発生時のみ）
  retryDelay: () => {
    return 500; // 遅延（ミリ秒）
  },
});

export default instance;
