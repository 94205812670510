<!--------------------------------------------------
  システム名：弁当注文ビフォサック（タブレット版）
  プログラム名：DebugPanel.vue
  モジュール名：デバッグ用
  備考：
-------------------------------------------------->
<template>
  <v-sheet>
    <v-card v-if="open" class="fixed-card ml-2 pa-2 d-flex align-center justify-center">
      <span class="mr-2">デバッグメニュー</span>
      <!-- フルスクリーンボタン -->
      <v-chip class="mx-1" style="width: 120px; display: flex; justify-content: center; align-items: center" @click.stop="onClickFullscreen" label>
        <strong>フルスクリーン</strong>
      </v-chip>
      <!-- リロードボタン -->
      <v-chip class="mx-1" style="width: 120px; display: flex; justify-content: center; align-items: center" @click.stop="onClickReload" label>
        <strong>リロード</strong>
      </v-chip>
      <!-- リセットボタン -->
      <v-chip class="mx-1" style="width: 120px; display: flex; justify-content: center; align-items: center" @click.stop="onClickReset" label>
        <strong>リセット</strong>
      </v-chip>
      <v-divider class="mx-2" vertical></v-divider>
      <!-- 閉じるボタン -->
      <v-chip class="mx-1" style="width: 80px; display: flex; justify-content: center; align-items: center" @click.stop="onClickMenu(false)" label>
        <strong>閉じる</strong>
      </v-chip>
    </v-card>
    <v-card v-if="!open" class="fixed-card ml-2 pa-2 d-flex align-center justify-center">
      <span class="mr-2">デバッグメニュー</span>
      <v-chip class="mx-1" style="width: 80px; display: flex; justify-content: center; align-items: center" @click.stop="onClickMenu(true)" label>
        <strong>開く</strong>
      </v-chip>
    </v-card>
  </v-sheet>
</template>

<style scoped>
.fixed-card {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
</style>

<script>
export default {
  name: "DebugPanel",
  data: () => ({
    open: false,
  }),
  methods: {
    // **************************************************
    // イベント処理
    // **************************************************
    // --------------------------------------------------
    // カードクリック時
    // --------------------------------------------------
    onClickMenu() {
      this.open = !this.open;
    },

    // --------------------------------------------------
    // フルスクリーンボタン押下時
    // --------------------------------------------------
    onClickFullscreen() {
      if (!document.fullscreenElement) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          // Safariや古いChrome用
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // Internet Explorer用
          document.msExitFullscreen();
        }
      }
    },

    // --------------------------------------------------
    // リロードボタン押下時
    // --------------------------------------------------
    onClickReload() {
      window.location.reload();
    },

    // --------------------------------------------------
    // リセットボタン押下時
    // --------------------------------------------------
    onClickReset() {
      // 登録情報リセット
      this.resetAuth();
      // 認証画面に遷移
      this.$router.push("/").catch(() => {});
    },

    // **************************************************
    // メソッド
    // **************************************************
    // --------------------------------------------------
    // 登録情報リセット
    // --------------------------------------------------
    resetAuth() {
      this.$store.commit("unsetAuth");
      this.$root.snackbar.showMessage("登録情報をリセットしました。", "info");
    },
  },
};
</script>
