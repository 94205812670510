import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

dayjs.locale(ja);

// 接続チェック
import "./connection";

// **************************************************
// 機能：遅延処理（デバッグ用：通信のラグを疑似的に再現します）
// 備考：！！！本番環境時は必ず0にしてください！！！
// **************************************************
export function sleep(time) {
  return new Promise(function (resolve) {
    window.setTimeout(resolve, time);
  });
}

// **************************************************
// 目的：日付形式変換
// 引数：文字列
// **************************************************
export function formatDate(value, format) {
  // 入力がない場合、空白を返却
  if (value == "") {
    return "[未入力エラー]";
  }
  // 形式が指定されていない場合、空白を返却
  if (format == "") {
    return "[形式エラー]";
  }
  // 日付が存在しない場合、空白を返却
  if (!dayjs(value).isValid()) {
    return "[妥当性エラー]";
  }
  return dayjs(value).format(format);
}

// **************************************************
// 目的：日付形式変換
// 引数：文字列
// **************************************************
export function addDate(value, count, unit) {
  // 引数が指定されていない場合、空白を返却
  if (value == "" || count == "" || unit == "") {
    return "";
  }
  // 日付が存在しない場合、空白を返却
  if (!dayjs(value).isValid()) {
    return "";
  }
  return dayjs(value).add(count, unit);
}

// **************************************************
// 目的：日付形式変換
// 引数：文字列
// **************************************************
export function subtractDate(value, count, unit) {
  // 引数が指定されていない場合、空白を返却
  if (value == "" || count == "" || unit == "") {
    return "";
  }
  // 日付が存在しない場合、空白を返却
  if (!dayjs(value).isValid()) {
    return "";
  }
  return dayjs(value).subtract(count, unit);
}

// **************************************************
// 目的：数値整形（カンマあり）
// 引数：文字列
// **************************************************
export function addComma(value) {
  // 全角→半角に変換
  value = zenkakuToHankaku(value);
  // 文字列→数値に変換
  value = Number(value);
  if (isNaN(value)) {
    // 数値ではない場合、0を返却
    return 0;
  }
  // カンマ区切りに変換
  value = value.toLocaleString();

  return value;
}

// **************************************************
// 目的：数値整形（カンマなし）
// 引数：文字列
// **************************************************
export function delComma(value) {
  // カンマ削除
  value = value.replace(/,/g, "");
  // 全角→半角に変換
  value = zenkakuToHankaku(value);
  // 文字列→数値に変換
  value = Number(value);
  if (isNaN(value)) {
    // 数値ではない場合、0を返却
    return 0;
  }

  return value;
}

// **************************************************
// 目的：文字列変換（全角数字→半角数字）
// 引数：文字列
// **************************************************
export function zenkakuToHankaku(str) {
  // 変換パターン定義
  const zenkakuNumbers = "０１２３４５６７８９";
  const hankakuNumbers = "0123456789";

  const regex = new RegExp(`[${zenkakuNumbers}]`, "g");
  return str.toString().replace(regex, (match) => {
    const index = zenkakuNumbers.indexOf(match);
    return index !== -1 ? hankakuNumbers[index] : match;
  });
}

// **************************************************
// 目的：日付範囲チェック
// 引数：文字列
// **************************************************
export function checkDateRange(value_st, value_ed) {
  // 入力がない場合、チェックしない
  if (value_st != "" || value_ed != "") {
    // 日付が不正の場合、チェックしない
    if (!dayjs(value_st).isValid() || !dayjs(value_ed).isValid()) {
      return false;
    }
    // 日付範囲チェック
    if (new Date(value_st) > new Date(value_ed)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：数値チェック
// 引数：文字列
// **************************************************
export function checkNum(value) {
  // チェックパターン定義
  const pattern = /^\d+$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 数値チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：数値チェック（カンマあり）
// 引数：文字列
// **************************************************
export function checkNumComma(value) {
  // チェックパターン定義
  const pattern = /^[\d,/]+$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 数値チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：数値チェック（スラッシュあり）
// 引数：文字列
// **************************************************
export function checkNumSlash(value) {
  // チェックパターン定義
  const pattern = /^[\d/]+$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 数値チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：数値チェック（ハイフンあり）
// 引数：文字列
// **************************************************
export function checkNumHyphen(value) {
  // チェックパターン定義
  const pattern = /^\d+(-\d*)*$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 数値チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：カナチェック（カナ、英数）
// 引数：文字列
// **************************************************
export function checkKana(value) {
  // チェックパターン定義
  const pattern1 = /^[\p{Script=Katakana}\p{Alphabetic}\p{Decimal_Number}]+$/u; // カタカナ、アルファベット、数字
  const pattern2 = /^[^\p{Script=Han}]+$/u; // 漢字以外
  // 入力がない場合、チェックしない
  if (value != "") {
    // カナチェック
    if (!pattern1.test(value) || !pattern2.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：文字列チェック（半角英数）
// 引数：文字列
// **************************************************
export function checkHanEisu(value) {
  // チェックパターン定義
  const pattern = /^[a-zA-Z0-9]*$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 文字列チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：文字列チェック（半角英数記号）
// 引数：文字列
// **************************************************
export function checkHanKigo(value) {
  // チェックパターン定義
  const pattern = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 文字列チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：ブラウザ取得
// **************************************************
export function getUserAgent() {
  let userAgent = window.navigator.userAgent.toLowerCase();
  let browserName = "";

  if (userAgent.indexOf("msie") != -1 || userAgent.indexOf("trident") != -1) {
    browserName = "Internet Explorer";
  } else if (userAgent.indexOf("edge") != -1) {
    browserName = "Microsoft Edge(legacy)";
  } else if (userAgent.indexOf("edg") != -1) {
    browserName = "Microsoft Edge";
  } else if (userAgent.indexOf("chrome") != -1) {
    browserName = "Google Chrome";
  } else if (userAgent.indexOf("safari") != -1) {
    browserName = "Safari";
  } else if (userAgent.indexOf("firefox") != -1) {
    browserName = "FireFox";
  } else if (userAgent.indexOf("opera") != -1) {
    browserName = "Opera";
  } else {
    browserName = "不明";
  }
  return browserName;
}

// **************************************************
// 目的：タッチデバイス判定
// **************************************************
export function checkTouchDevice() {
  if ("ontouchstart" in window || navigator.maxTouchPoints > 0 || window.matchMedia("(pointer:coarse)").matches) {
    return true;
  }
  return false;
}

// **************************************************
// 目的：画面解像度判定
// **************************************************
export function checkDisplaySize() {
  // 画面サイズを取得
  const displayWidth = screen.width;
  const displayHeight = screen.height;
  if (displayWidth < 1024 || displayHeight < 768) {
    return false;
  }
  return true;
}

// **************************************************
// 目的：ウィンドウサイズ判定
// **************************************************
export function checkWindowSize() {
  // ウィンドウサイズを取得
  const windowWidth = document.documentElement.clientWidth;
  const windowHeight = document.documentElement.clientHeight;
  if (windowWidth < 1024 || windowHeight < 768) {
    return false;
  }
  return true;
}

// **************************************************
// 目的：ブラウザ判定
// **************************************************
export function checkBrowser() {
  // 対応ブラウザ
  const browserList = ["Microsoft Edge", "Google Chrome"];
  // ブラウザ情報を取得
  const browserName = getUserAgent();
  if (!browserList.includes(browserName)) {
    return false;
  }
  return true;
}

export async function scanNFC() {
  if ("NDEFReader" in window) {
    const nfcReader = new window.NDEFReader();
    let scanController = new AbortController(); // 中断用のコントローラ

    const startScan = () => {
      scanController = new AbortController(); // 新しい中断用コントローラを作成

      nfcReader
        .scan({ signal: scanController.signal }) // 中断可能なスキャン
        .then(() => {
          console.log("NFCスキャン開始...");

          nfcReader.onreading = (event) => {
            if (event.serialNumber) {
              this.serialNumber = event.serialNumber.replace(/:/g, "").toUpperCase();
              console.log("FeliCa UID (IDm):", event.serialNumber.replace(/:/g, "").toUpperCase());

              this.onLoadIdm();
            } else {
              console.log("FeliCa UIDが取得できませんでした。");
            }
          };
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            console.log("NFCスキャンが中断されました。");
          } else {
            console.log("NFCエラー:", error);
          }
        });
    };

    const stopScan = () => {
      scanController.abort(); // スキャンを中断
      console.log("NFCスキャンを停止しました。");
    };

    startScan();

    // 必要に応じて、外部から stopScan() を呼び出せるようにする
    this.stopNfcScan = stopScan;
  } else {
    console.log("Web NFC API はこの端末では利用できません。");
    this.$root.snackbar.showMessage("Web NFC API はこの端末では利用できません。", "info");
  }
  if ("NDEFReader" in window) {
    const nfcReader = new window.NDEFReader();
    nfcReader
      .scan()
      .then(() => {
        console.log("NFCスキャン開始...");
        nfcReader.onreading = (event) => {
          console.log("FeliCa UID (IDm):", event.serialNumber.replace(/:/g, "").toUpperCase());
        };
      })
      .catch((error) => console.log("NFCエラー:", error));
  } else {
    console.log("Web NFC API はこの端末では利用できません。");
  }
}
