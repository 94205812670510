<!--------------------------------------------------
  システム名：弁当注文ビフォサック（タブレット版）
  プログラム名：SystemBar.vue
  モジュール名：システムバー
  備考：
-------------------------------------------------->
<template>
  <v-system-bar app>
    <!-- バージョン情報 -->
    <span @mousedown="startPress" @touchstart="startPress" @mouseup="endPress" @touchend="endPress" @mouseleave="endPress" @touchcancel="endPress" @click="confirmView = true">
      <strong>Ver. {{ $version }}</strong>
    </span>
    <!-- 接続状態 -->
    <span class="mx-2">
      <!-- APIサーバー -->
      <v-tooltip bottom :color="$store.state.statAPI ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="$store.state.statAPI ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">language</v-icon>
        </template>
        <span>API</span>
      </v-tooltip>
      <!-- データベース -->
      <v-tooltip bottom :color="$store.state.statDB ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="$store.state.statDB ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">storage</v-icon>
        </template>
        <span>データベース</span>
      </v-tooltip>
      <!-- WebSocketサーバー -->
      <!-- <v-tooltip v-if="$store.state.kigyoIcOption" bottom :color="$store.state.statWebSocket ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="$store.state.statWebSocket ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">settings_ethernet</v-icon>
        </template>
        <span>WebSocket</span>
      </v-tooltip> -->
      <!-- カードリーダー -->
      <v-tooltip v-if="$store.state.kigyoIcOption" bottom :color="$store.state.statCardReader ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="$store.state.statCardReader ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">badge</v-icon>
        </template>
        <span>カードリーダー</span>
      </v-tooltip>
    </span>
    <v-spacer></v-spacer>
    <!-- 現在日時 -->
    <span>
      <strong>{{ $store.state.nowDate }}</strong>
    </span>

    <!-- ********** 予約内容確認（ダイアログ） ここから ********** -->
    <v-dialog v-model="confirmView" min-width="420px" max-width="420px" no-click-animation>
      <v-card>
        <v-card-title>登録情報をリセットしますか？</v-card-title>

        <v-card-text>
          <div class="mb-2">
            <div>システムに保存されている情報を削除し、</div>
            <div>認証をやり直す場合は「リセット」を押してください。</div>
          </div>
          <div class="mb-2">
            <div>「リセット」を押すと、認証画面に戻ります。</div>
          </div>
          <div>※契約に関する情報は削除されません。</div>
        </v-card-text>

        <v-card-actions>
          <v-container fluid>
            <v-row>
              <v-col cols="6" class="pa-0 pr-1">
                <v-btn @click="onClickReset()" color="error" dark large block depressed>リセット</v-btn>
              </v-col>
              <v-col cols="6" class="pa-0 pl-1">
                <v-btn @click="confirmView = false" color="blue-grey" dark large block depressed>キャンセル</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ********** 予約内容確認（ダイアログ） ここまで ********** -->
  </v-system-bar>
</template>

<script>
export default {
  name: "SystemBar",
  data: () => ({
    timer: null,
    isLongPress: false,
    longPressDuration: 5000, // 長押し判定までの時間（ミリ秒）
    confirmView: false, // ダイアログ表示
  }),
  methods: {
    // **************************************************
    // イベント処理
    // **************************************************
    // --------------------------------------------------
    // 長押し開始
    // --------------------------------------------------
    startPress() {
      this.timer = setTimeout(() => {
        // 企業コードが保存されていない場合は、何もしない
        if (this.$store.state.kigyoCode == "") {
          return;
        }
        // ログイン画面以外では、何もしない
        if (this.$route.path != "/login") {
          return;
        }
        // ダイアログを開く
        this.confirmView = true;
      }, this.longPressDuration);
    },

    // --------------------------------------------------
    // 長押し終了
    // --------------------------------------------------
    endPress() {
      clearTimeout(this.timer); // タイマーをクリア
      if (!this.isLongPress) {
        this.isLongPress = false; // 長押し判定が無かった場合
      }
    },

    // --------------------------------------------------
    // リセットボタン押下時
    // --------------------------------------------------
    onClickReset() {
      // 登録情報リセット
      this.resetAuth();
      // 認証画面に遷移
      this.$router.push("/").catch(() => {});
      // ダイアログを閉じる
      this.confirmView = false;
    },

    // **************************************************
    // メソッド
    // **************************************************
    // --------------------------------------------------
    // 登録情報リセット
    // --------------------------------------------------
    resetAuth() {
      this.$store.commit("unsetAuth");
      this.$root.snackbar.showMessage("登録情報をリセットしました。", "info");
    },
  },
};
</script>
